import Vue from "vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { extend } from "vee-validate"
import {
  required,
  email,
  min,
  confirmed,
  numeric,
  is_not,
  min_value,
  max_value,
  is,
} from "vee-validate/dist/rules"
import { toTitleCase } from "@evercam/shared/utils"
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

extend("phone_number", {
  validate: (value) => value.match(/^[0-9]+$/) !== null,
  message: "The {_field_} must contain only numbers and no spaces",
})

extend("equal_to", {
  ...is,
  params: ["other"],
  message: (value, { other }) => {
    return `${value} entered no equal to: ${other}`
  },
})

extend("requiredPasswordConfirmation", {
  ...required,
  message: "Re-enter password",
})

extend("min", {
  ...min,
  message: (field, { length }) => {
    return `${toTitleCase(field)} must be at least ${length} characters`
  },
})
extend("required", {
  ...required,
  message: "Enter {_field_}",
})
extend("min_value", {
  ...min_value,
  message: "{_field_} must be minimum {min}",
})
extend("max_value", {
  ...max_value,
  message: "{_field_} must be maximum {max}",
})
extend("requiredCameras", {
  ...required,
  message: "Select at least one camera",
})
extend("requiredRecipients", {
  validate: (value) => {
    const emails = value.map((obj) => obj.email ?? obj)

    return required.validate(value) && email.validate(emails)
  },
  message: "Enter at least one valid recipient's email",
})
extend("requiredDays", {
  ...required,
  message: "Select at least one day",
})
extend("email", {
  ...email,
  message: "Enter a valid email",
})
extend("confirmed", {
  ...confirmed,
  message: "Please enter the same password as above",
})
extend("numeric", {
  ...numeric,
  message: "{_field_} field may only contain numeric characters",
})
extend("is_not", {
  ...is_not,
  message: "New password should be different from current password",
})

extend("number_phone", {
  validate: (value) =>
    value.match(
      /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
    ) !== null,
  message: "Please enter a valid phone number e.g. +353xxxxxxxxxx",
})

extend("mp4_file", {
  validate: (value) => {
    return value.endsWith("mp4")
  },
  message: "Please upload a valid mp4 file",
})

extend("url", {
  validate: (value) =>
    /^(https?:\/\/)(([\w-]+\.[\w-]+\.)+[\w-]{2,}|localhost)(:\d+)?(\/[\w-./?%&=]*)?$/.test(
      value
    ),
  message: "{_field_} must be a valid url",
})

extend("username", {
  validate: (value) => /^[a-zA-Z0-9._-]{2}[a-zA-Z0-9 ._-]*$/.test(value),
  message:
    "{_field_} may only contain letters, numbers, spaces, dots, dashes, and underscores. The first two characters cannot be spaces.",
})
